import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ActionButton from '../components/ActionButton';
import '../styles/home.css';
import { SUPPORTED_SPORTS, formatSportsName } from '../utils/racqy';

import { navigate } from 'gatsby';

import { createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';

// Image imports
import getLocation from '../hooks/getLocation';
import { hideLoader, showLoader } from '../utils/domSelector';
const searchClient = algoliasearch(
  'KMU3PRF4JV',
  '3c04a974c18a230e733c7e8e14e4f5e6'
);

function AutocompleteField({
  gpsLocation,
  autocompleteState,
  setAutocompleteState,
  onItemClick,
  sport,
}) {
  // (1) Create a React state.

  const autocomplete = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state);
        },
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: 'locations',
              getItemInputValue({ item }) {
                return item.query;
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: 'Locations',
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: '<span>',
                        highlightPostTag: '</span>',
                        getRankingInfo: true,
                        aroundLatLngViaIP: true,
                        filters: `sports:${sport}`,
                        aroundRadius: 'all',
                      },
                      // }, gpsLocation ? { aroundLatLng: `${gpsLocation?.latitude},${gpsLocation?.longitude}` } : { aroundLatLngViaIP: true }),
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                return item.url;
              },
            },
          ];
        },
      }),
    [gpsLocation, sport]
  );

  return (
    <div
      className="aa-Autocomplete search-input-container"
      {...autocomplete.getRootProps({})}
    >
      <input
        className="aa-Input search-input"
        {...autocomplete.getInputProps({})}
        placeholder="Club, city or leave me empty..."
      />
      <div
        className="aa-Panel autocomplete-panel"
        {...autocomplete.getPanelProps({})}
      >
        {autocompleteState.isOpen &&
          autocompleteState.collections.map((collection, index) => {
            const { source, items } = collection;

            return (
              <div key={`source-${index}`} className="aa-Source">
                {items.length > 0 && (
                  <ul className="aa-List" {...autocomplete.getListProps()}>
                    {items.map((item) => (
                      <li
                        key={item.objectID}
                        className="aa-Item"
                        {...autocomplete.getItemProps({
                          item,
                          source,
                        })}
                        onClick={() => onItemClick(item)}
                      >
                        {/* {console.log("ITEM:", item)} */}
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item._highlightResult?.name.value ?? item.name,
                          }}
                        />
                        <p>{item.address}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );

  // ...
}

// markup
const Home = ({ location }) => {
  const { gpsLocation } = getLocation();
  const [autocompleteState, setAutocompleteState] = React.useState({});
  const [sport, setSport] = useState('tennis');

  useEffect(() => {
    hideLoader();

    return () => showLoader();
  }, []);

  return (
    <>
      <Helmet>
        <title>Racqy</title>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/@algolia/autocomplete-theme-classic"
        />
      </Helmet>
      <main id="home">
        {/* <InstantSearch indexName={"Locations"} searchClient={searchClient} searchState={searchState} onSearchStateChange={setSearchState} createURL={createURL}>
          <VirtualSearchBox />
          <div id="autocomplete"></div>
        </InstantSearch> */}
        <section id="search">
          <div className="background-anim">
            <div id="img-1" />
            <div id="img-2" />
          </div>
          <div className="page-content">
            <div className="space-y-12 search-container">
              <div className="description">
                <h1>Hey, find sport locations here!</h1>
                <p>
                  Find available times for all sports , locations around the
                  world.
                </p>
              </div>
              <div className="content">
                <select
                  name="sport"
                  id="sports"
                  placeholder="Sport"
                  value={sport}
                  onChange={(evt) => setSport(evt.target.value)}
                >
                  {SUPPORTED_SPORTS.map((sport, index) => (
                    <option value={sport} key={`sport-${index}`}>
                      {formatSportsName(sport)}
                    </option>
                  ))}
                </select>
                {/* <Multiselect
                  options={this.state.options} // Options to display in the dropdown
                  selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                  onSelect={this.onSelect} // Function will trigger on select event
                  onRemove={this.onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  /> */}
                <AutocompleteField
                  autocompleteState={autocompleteState}
                  setAutocompleteState={setAutocompleteState}
                  sport={sport}
                  // gpsLocation={gpsLocation}
                  // onItemClick={(item) => console.warn("ITEM CLICKED", item)}
                  onItemClick={(item) =>
                    navigate(
                      `/search?query=${item.name}&sport=${sport}${
                        gpsLocation
                          ? `&coords=${gpsLocation.latitude},${gpsLocation.longitude}`
                          : ''
                      }`
                    )
                  }
                />
                {/* <Autocomplete openOnFocus searchClient={locationsSearchClient} /> */}
                {/* <input className="search-input" value={query} onChange={evt => setQuery(evt.target.value)} placeholder="Club, city or leave me empty..."/> */}
                <ActionButton
                  title="Search"
                  tint="blue"
                  onClick={() => {
                    navigate(
                      `/search?query=${
                        autocompleteState.query ?? ''
                      }&sport=${sport}${
                        gpsLocation
                          ? `&coords=${gpsLocation.latitude},${gpsLocation.longitude}`
                          : ''
                      }`
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Home;
