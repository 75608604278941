import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import Layout from '../components/Layout';

import Helmet from 'react-helmet';
import '../styles/main.css';

import Home from './home';
import Search from './search';

// markup
const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <Router>
        <Home path="/" />
        <Search path="/search" />
      </Router>
    </Layout>
  );
};

export default IndexPage;
